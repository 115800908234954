import { reactive } from 'vue'
import { useForm } from '../../Base/useForm'
import { passwordValid } from '@/utils/validator/fields'
import { FormHandler } from '@/utils/use/formHandler'
import { changePassword } from '@/http/auth/authApi'
import { helpers } from '@vuelidate/validators'
import { Alert } from '@/extension/Alert/Alert'
import { useRouter } from 'vue-router'

export function useSavePasswordForm () {
  const router = useRouter()

  const formData = reactive({
    password: '',
    confirmPassword: ''
  })

  const rules = {
    password: {
      ...passwordValid,
      equalPassword: helpers.withMessage('Пароли не совпадают', () => formData.confirmPassword === formData.password)
    },
    confirmPassword: {
      ...passwordValid,
      equalPassword: helpers.withMessage('Пароли не совпадают', () => formData.confirmPassword === formData.password)
    }
  }

  const formAction = async () => {
    try {
      const { message } = await changePassword(form.route.query, formData.password)
      await router.push({ name: 'login' })
      await Alert.show('success', message)
    } catch (e) {
      await Alert.show('error', e)
    }
  }

  const form = FormHandler(formAction)

  return {
    ...useForm(rules, formData, form.handlerErrors),
    serverErrors: form.serverErrors
  }
}
