<template>
  <div class="main-container main-login mt-200 mb-250">
    <div class="container">

      <form @submit.prevent="onSubmit" class="form form--login">
        <div class="block__subtitle mb-30">
          Восстановление пароля
        </div>
        <VInput
          name="password"
          id="password"
          v-model="formData.password"
          :errors="v$.password.$errors"
          :server-errors="serverErrors.value?.password"
          type="password"
          @blur="validateField"
          @input="validateField"
          :class-input="['input input-long input-placeholder-black input-border-bottom']"
          placeholder="Новый пароль"
        />
        <VInput
          name="confirmPassword"
          id="confirmPassword"
          v-model="formData.confirmPassword"
          :errors="v$.confirmPassword.$errors"
          :server-errors="serverErrors.value?.confirmPassword"
          type="password"
          @blur="validateField"
          @input="validateField"
          :class-input="['input input-long input-placeholder-black input-border-bottom']"
          placeholder="Повторите пароль"
        />

        <VButtonLoading
          :is-disabled="isSend"
          color="green"
        >
          Сохранить
        </VButtonLoading>
      </form>

    </div>
  </div>
  <TheFooter />
</template>

<script>
import TheFooter from '@/components/TheFooter'
import { useSavePasswordForm } from '@/use/Form/Auth/useSavePasswordForm'
import VInput from '@/components/ui/form/VInput'
import VButtonLoading from '@/components/ui/buttons/VButtonLoading'

export default {
  setup () {
    return {
      ...useSavePasswordForm()
    }
  },
  components: {
    TheFooter,
    VInput,
    VButtonLoading
  }
}
</script>
